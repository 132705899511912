<template>
    <div class="app-content">
        <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
        >
            <el-row type="flex" justify="center">
                <el-col :sm="20" :lg="16">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <b>文章信息</b>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="文章封面" prop="articleCover">
                                    <ImageUpload
                                        @input="coverClick"
                                        :value="form.articleCover"
                                        :fileSize="2"
                                        :limit="1"
                                    ></ImageUpload>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="名称" prop="articleName">
                                    <el-input
                                        v-model="form.articleName"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="文章类型"
                                    prop="articleType"
                                >
                                    <el-select
                                        style="width: 100%"
                                        v-model="form.articleType"
                                        placeholder="请选择"
                                        clearable
                                    >
                                        <el-option
                                            v-for="dict in articleTypeList"
                                            :key="dict.value"
                                            :label="dict.label"
                                            :value="dict.value"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="8">
                                <el-form-item
                                    label="信息来源"
                                    prop="informationSource"
                                >
                                    <el-input
                                        v-model="form.informationSource"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item
                                    label="摘要"
                                    prop="articleAbstract"
                                >
                                    <el-input
                                        v-model="form.articleAbstract"
                                        type="textarea"
                                        :rows="2"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card class="box-card" style="margin-top: 10px">
                        <div slot="header" class="clearfix">
                            <b><span style="color: red">*</span>文章内容</b>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="8" style="width: 100%">
                                <el-form-item prop="articleContent">
                                    <editor
                                        v-model="form.articleContent"
                                        :min-height="192"
                                /></el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card
                        class="box-card bottom-card"
                        style="margin-top: 10px"
                    >
                        <el-row type="flex" justify="end">
                            <el-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                                >提 交</el-button
                            >
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    articleDetailApi,
    articleAddApi,
    articleUpdateApi,
} from '@/api/article'
export default {
    data() {
        return {
            articleTypeList: [
                {
                    label: '政策解读',
                    value: '2',
                },
                {
                    label: '金融动态',
                    value: '3',
                },
                {
                    label: '展会资讯',
                    value: '4',
                },
            ],
            form: {},
            rules: {
                articleName: [
                    { required: true, message: '请输入文章名称', trigger: 'blur' },
                ],
                articleType: [
                    {
                        required: true,
                        message: '请选择文章类型',
                        trigger: 'blur',
                    },
                ],
                articleContent: [
                    {
                        required: true,
                        message: '请输入文章内容',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        if (this.$route.query.id) {
            this.articleDetailFun()
        }
    },
    methods: {
        // 获取文章详情
        async articleDetailFun() {
            var params = {
                id: this.$route.query.id,
            }
            var res = await articleDetailApi(params)
            this.form = res
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.$route.query.id) {
                        this.articleUpdateFun()
                    } else {
                        this.articleAddFun()
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 新增文章
        async articleAddFun() {
            var res = await articleAddApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '新增成功',
                    type: 'success',
                })
                this.dialogVisible = false
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '新增失败',
                    type: 'error',
                })
            }
        },
        // 更新文章
        async articleUpdateFun() {
            delete this.form.createTime
            delete this.form.publishTime
            var res = await articleUpdateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.dialogVisible = false
                // this.districtListFun()】
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
        // 封面
        coverClick(value) {
            this.form.articleCover = value
        },
    },
}
</script>

<style scoped>
.app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
    /* margin-top: 20px; */
}
::v-deep .el-form-item {
    width: 100%;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>
